import { extendTheme } from "@chakra-ui/react";

const Link = {
  baseStyle: {
    color: "primary.500",
    _hover: {
      opacity: 0.75,
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
};

const Button = {
  baseStyle: {
    borderRadius: "full",
  },
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "primary.500",
      color: "primary.500",
      _hover: {
        opacity: 0.75,
        bg: "transparent",
      },
      _active: {
        bg: "transparent",
      },
    },
  },
  sizes: {
    sm: {
      px: 6,
      height: 10,
      minW: 10,
    },
    md: {
      px: 8,
      height: 12,
      minW: 12,
    },
    lg: {
      px: 10,
      height: 16,
      minW: 16,
    },
  },
};

const Input = {
  sizes: {
    md: {
      field: {
        borderRadius: "xl",
      },
    },
  },
  variants: {
    filled: {
      field: {
        bg: "primary.50",
        color: "primary.500",
        height: 12,
        _focus: {
          bg: "white",
          borderColor: "primary.500",
        },
        _hover: {
          bg: "primary.100",
        },
        _placeholder: {
          color: "primary.200",
        },
      },
    },
  },
  defaultProps: {
    variant: "filled",
  },
};

const Textarea = {
  sizes: {
    md: {
      ...Input.sizes.md.field,
      py: 4,
    },
  },
  variants: {
    filled: Input.variants.filled.field,
  },
  defaultProps: Input.defaultProps,
};

const Heading = {
  baseStyle: {
    fontWeight: "semibold",
    color: "primary.500",
  },
};

const Text = {
  baseStyle: {
    color: "gray.500",
  },
};

const theme = {
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {
    primary: {
      50: "#e8ebf5",
      100: "#d9ddee",
      200: "#b3bbdc",
      300: "#8d99cb",
      400: "#6777b9",
      500: "#4155a8",
      600: "#344486",
      700: "#273365",
      800: "#1a2243",
      900: "#0d1122",
    },
    secondary: {
      50: "#fef5f5",
      100: "#feeeee",
      200: "#fdddde",
      300: "#fdcdcd",
      400: "#fcbcbd",
      500: "#fbabac",
      600: "#c9898a",
      700: "#976767",
      800: "#644445",
      900: "#322222",
    },
    gray: {
      100: "#e7e7e7",
      200: "#d0d0d0",
      300: "#b8b8b8",
      400: "#a1a1a1",
      500: "#898989",
      600: "#6e6e6e",
      700: "#525252",
      800: "#373737",
      900: "#1b1b1b",
    },
  },
  shadows: {
    outline: "none",
  },
  sizes: {
    container: {
      lg: "1140px",
    },
  },
  components: {
    Link,
    Button,
    Input,
    Textarea,
    Heading,
    Text,
  },
};

export default extendTheme(theme);
